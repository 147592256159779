@charset "utf-8";



@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.archive__item a {
    text-decoration: none;
}

.archive__item a:hover {
    text-decoration: underline;
}


.page__hero--overlay {
    background-position: top;
    min-height: 350px;
}